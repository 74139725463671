.Header {
  width: 100%;
  height: 125px;
  position: fixed;
  top: 0;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #1F6F78, #93E4C1) 100;
  display: flex;
  align-items: flex-start; 
  z-index: 1;
  background-color: white;
  align-items: center;
  padding-left: 30px;
}

.Content {
  /* font-family: 'MPlus1-Regular'; */
  position: absolute;
  top: 125px;
  width: 100%;
  height: calc(100vh - 125px);
  display: flex;
  justify-content: center;
  align-items: center;
}


/********** Main **********/
.App {
  text-align: center;
  font-size: x-large;
  min-height: 100vh;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif, Helvetica, sans-serif;
}

.App-mobile {
  text-align: center;
  font-size: medium;
  min-height: 100vh;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif, Helvetica, sans-serif;
}

/********** Content **********/
.container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
  min-height: 100%;
  position: relative;
  padding-top: 250px;
}

.container-mobile {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
  min-height: 100%;
  position: relative;
  padding-top: 200px;
}

.content {
  width: 70vw;
  font-size:large;
  line-height: 18pt;
}

#leftSection {
  text-align: justify;
  width: 60%;
  position: relative;
  left: 10%;
}

#rightSection {
  text-align: justify;
  width: 55%;
  position: relative;
  left: 35%;
}

#rightSection h3 {
  text-align: right;
}

#mobileSection {
  text-align: left;
}

#picSection {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(-13vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1;
  background-color: white;
  display: flex;
  justify-content: center;
  padding-top: 15vh;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;

}

/********** Header **********/
.App-header {
  border-bottom: 3px solid #A89870;
  min-height: 120px;
  display: flex;
  align-items: flex-start; 
  padding-top: 20px;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: white;
}

.header-content {
  width: 70vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content ul {
  list-style: none;
  text-align: right;
}

.App-header-mobile {
  border-bottom: 3px solid #A89870;
  min-height: 80px;
  display: flex;
  align-items: flex-start; 
  justify-content: center;
  color: black;
  font-size: medium;
  padding-top: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: white;
}

.header-content-mobile {
  width: 90vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content-mobile ul {
  list-style: none;
  text-align: right;
  white-space: nowrap;
  padding-left: 15px;
}

/********** Footer **********/
.App-footer {
  /* position: fixed; */
  position: relative;
  bottom: 0;
  height: 5rem;
  border-top: 3px solid #A89870;
  color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  margin-top: 10px;
}

.footer-content {
  width: 70vw; 
  padding: 3px;
  font-size: large;
}

.footer-content-mobile {
  width: 90vw; 
  padding: 3px;
  font-size: medium;
  height: 5rem;
}

.alignLeft {
  height: 5rem;
	float: left;
  margin: 0px;
  display: flex;
  align-items: center;
}

.alignRight {
  color: #A89870;
  height: 5rem;
	float: right;
  margin: 0px;
  display: flex;
  align-items: center;
}

.fc {
  padding-left: 50px;
  width: 300px;
  text-align: left;
}

.link, .link:visited {
  text-decoration: none;
  color: black;
}

.link:hover {
  color: #A89870;
}

/********** Sidebar **********/
.sidebar {
  position: fixed;
  right: 0px;
  min-width: 100px;
  align-items: right; 
  width: 5vw;
  z-index: 2;
}

.sidebar ul {
  list-style: none;
  margin: 100px 0 0 0;
  justify-content: space-between;
}

.sidebar ul li {
  padding: 30px 0;
}

/********** Popup **********/
.popup-content {
  margin: auto;
  background: #A89870;
  width: 50%;
  padding: 5px;
  color: white;
}
.popup-arrow {
  color: #A89870;
  top: calc(50% - 3px);
}
[role='tooltip'].popup-content {
  width: 220px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  border-radius: 10px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.popup-content a, a:visited {
  text-decoration: none;
  color: white;
}

.popup-content a:hover {
  text-decoration: underline;
}

/********** Impressum & Datenschutz **********/
.impressum {
  margin: auto;
  font-size: large;
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
  padding-top: 150px;
}

.impressum p {
  line-height: 1.6;
}

.impressum-mobile {
  margin: auto;
  font-size: large;
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
  padding-top: 100px;
}

.impressum-mobile p {
  line-height: 1.6;
}

/********** Scroll to top **********/
.top-to-btm{
  position: relative;
}
.icon-position{
  position: fixed;
  bottom: 5.7rem;
  right: 5px;
  z-index: 20;
}
.icon-style{
  background-color: #A89870;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}
.icon-style:hover{
  animation: none;
  background: black;
  color: white;
}
